<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col class="font-weight-bold">
          {{ dataInterview.customer_company }}
        </b-col>
        <b-col
          cols="auto"
          class="text-right text-primary font-weight-light d-flex align-items-center"
        >
          <FeatherIcon
            icon="UserIcon"
          />
          {{ dataInterview.username? dataInterview.username : dataInterview.dealer_user }}
        </b-col>
      </b-row>
    </b-card-body>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th>MARKA</b-th>
          <b-th>KONU</b-th>
          <b-th>DURUM</b-th>
          <b-th>İLK TEMAS</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ dataInterview.brand }}</b-td>
          <b-td>{{ dataInterview.interview_subject }}</b-td>
          <b-td>
            {{ dataInterview.interview_status }}
            <span class="text-muted font-small-2">({{ dataInterview.status === '0'? 'Açık Görüşme' : 'Kapalı Görüşme' }})</span>
          </b-td>
          <b-td>{{ dataInterview.meet }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'

export default {
  name: 'InterviewInfo',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
  },
  computed: {
    dataInterview() {
      return this.$store.getters['interviews/dataInterview']
    },
  },
}
</script>
