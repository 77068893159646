<template>
  <b-card no-body>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th>TARİH</b-th>
          <b-th>DURUM</b-th>
          <b-th colspan="2">
            GÖRÜŞME NOTU
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(line,index) in dataInterview.lines"
          :key="line.id"
        >
          <b-td class="text-nowrap">
            {{ moment(line.created).format('LLLL') }}
            <div class="text-primary font-small-2">
              {{ line.meet }}
            </div>
            <div class="text-info font-small-2">
              Temsilci: {{ line.username ? line.username : line.dealer_user }}
            </div>
          </b-td>
          <b-td class="text-nowrap">
            {{ line.interview_status }}
          </b-td>
          <b-td class="w-100">
            <div>
              {{ line.content }}
            </div>
            <div
              v-for="file in line.files"
              :key="file.id"
              class="text-primary font-small-2 d-inline-flex"
            >
              <b-link
                :href="mediaUrl + '/media/interviews/' +file.filename"
                target="_blank"
                class="mr-1"
              >
                <FeatherIcon icon="PaperclipIcon" />
                Dosya Eki
              </b-link>
            </div>
            <div
              v-if="line.modified"
              class="text-info font-small-2 mt-1"
            >
              {{ moment(line.modified).format('LLLL') }} tarihinde güncellendi.
            </div>
          </b-td>
          <b-td>
            <template v-if="line.id_dealer_users">
              <line-buttons
                :disabled-status="userData.id_dealers ? userData.id !== line.id_dealer_users : true"
                :data-id="line.id"
                :delete-action="removeLine"
                :edit-action="editLine"
                :upload-action="uploadLine"
                :last-line="(dataInterview.lines.length === (index + 1))"
              />
            </template>
            <template v-else>
              <line-buttons
                :disabled-status="userData.id !== line.id_users ? true : false"
                :data-id="line.id"
                :delete-action="removeLine"
                :edit-action="editLine"
                :upload-action="uploadLine"
                :last-line="(dataInterview.lines.length === (index + 1))"
              />
            </template>

          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <modal-edit-line
      :modal-show="editModal"
      :last-line="lastLine"
      :save-line="saveLine"
      :close-modal="closeEditModal"
    />
    <modal-upload-line
      :modal-show="uploadModal"
      :upload-file="uploadFile"
      :close-modal="closeUploadModal"
      :line-id="lineId"
    />
  </b-card>
</template>

<script>
import {
  BCard, BTableSimple, BThead, BTr, BTh, BTbody, BTd, BLink,
} from 'bootstrap-vue'
import LineButtons from '@/views/Admin/Interviews/elements/LineButtons.vue'
import ModalEditLine from '@/views/Admin/Interviews/elements/ModalEditLine.vue'
import ModalUploadLine from '@/views/Admin/Interviews/elements/ModalUploadLine.vue'

export default {
  name: 'InterviewInfo',
  components: {
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BLink,
    LineButtons,
    ModalEditLine,
    ModalUploadLine,
  },
  props: {
    getData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      mediaUrl: this.$store.state.app.baseURL,
      editModal: false,
      uploadModal: false,
      lineId: '',
      lastLine: false,
    }
  },
  computed: {
    dataInterview() {
      return this.$store.getters['interviews/dataInterview']
    },
  },
  methods: {
    editLine(id, lastLine) {
      this.$store.dispatch('interviews/getDataLine', id)
        .then(res => {
          if (res.id) {
            this.editModal = !this.editModal
            this.lastLine = lastLine
          }
        })
    },
    uploadLine(id) {
      this.lineId = id
      this.uploadModal = !this.uploadModal
    },
    removeLine(id) {
      this.$store.dispatch('interviews/removeLine', id)
        .then(status => {
          if (status) {
            this.getData()
          }
        })
    },
    saveLine(data, lastLine) {
      const postData = data
      postData.lastLine = !!lastLine
      this.$store.dispatch('interviews/updateLine', postData)
        .then(status => {
          if (status) {
            this.editModal = false
            this.getData()
          }
        })
    },
    uploadFile(data, id) {
      const postData = data
      postData.id_interview_lines = id
      this.$store.dispatch('interviewFiles/uploadData', postData)
        .then(status => {
          if (status) {
            this.uploadModal = false
            this.getData()
          }
        })
    },
    closeEditModal() {
      this.editModal = false
    },
    closeUploadModal() {
      this.uploadModal = false
    },
  },
}
</script>
